Cdx.ready(function () {
  $('.o-js-adjusted-textarea').each(function(){
    adjustTextarea(this);
  });

  $('.o-js-adjusted-textarea').on('keyup', function() {
    adjustTextarea(this);
  });

  function adjustTextarea(element) {
    element.style.height = '1px';
    var maxHeight = 270;
    var newHeight = 25 + element.scrollHeight;

    if (newHeight > maxHeight) {
      newHeight = maxHeight
    }

    element.style.height = newHeight + 'px';
  }
});
