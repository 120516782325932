Cdx.ready(function () {
  $('.o-js-assistance-contract-selector').on('change', function() {
    var contract_type = $(this).find(':selected').data('contract-type');
    var company = $(this).find(':selected').data('company');
    if (contract_type != undefined) {
      $('.o-js-company').attr("disabled", true);
    } else {
      $('.o-js-company').show();
    }
  }).trigger('change');
});
