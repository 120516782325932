Cdx.ready(function () {
  $('.o-js-global-company-search select').on('change', function() {
    var link = $(this).closest('.o-js-global-company-search').find('a');

    if($(this).val()) {
      link.attr('href', link.attr('data-source') + 'company-' + $(this).val());
    } else {
      link.attr('href', '/');
    }
  });
});
