import Rails from '@rails/ujs'

Cdx.ready(function () {
  $(document).on('change', '.o-js-network_select', function()
  {
    let network_resources_fields = $(this).parent().parent().next('.o-js-network_resources')

    if ($(this).val() === '') {
      network_resources_fields.hide()
    } else {
      Rails.ajax({
        url: '/networks/' + $(this).val() + '/available_network_resources.json',
        type: 'GET',
        success: function(data) {
          // Update dhcp select
          let dhcps = data.dhcps
          let dhcp_select = network_resources_fields.find('.o-js-dhcp_id select')
          let dhcp_select_val = dhcp_select.val()

          dhcp_select.find('option').each(function() {
            if ($(this).val() !== '') {
              $(this).remove()
            }
          })

          for (var i = 0; i < dhcps.length; i++) {
            let dhcp = dhcps[i]
            let selected = (dhcp_select_val == dhcp.id) ? 'selected' : ''

            dhcp_select.append('<option value="' + dhcp.id + '" ' + selected + '>' + dhcp.label + '</option>')
          }

          // Update static_ip select
          let free_ips = data.free_ips
          let static_ip_select = network_resources_fields.find('.o-js-static_ip select')
          let static_ip_select_val = static_ip_select.val()

          static_ip_select.find('option').each(function() {
            if ($(this).val() !== '') {
              $(this).remove()
            }
          })

          if (static_ip_select_val != '') {
            static_ip_select.append('<option value="' + static_ip_select_val + '" selected>' + static_ip_select_val + '</option>')
          }

          for (var y = 0; y < free_ips.length; y++) {
            let ip = free_ips[y]
            static_ip_select.append('<option value="' + ip + '">' + ip + '</option>')
          }

          // Show fields
          network_resources_fields.show()
        }
      });
    }
  })

  $(document).on('change', '.o-js-dhcp_id select', function()
  {
    if ($(this).val() !== '')
    {
      let static_ip_select = $(this).parent().parent().next('.o-js-static_ip').find('select')
      static_ip_select.val('')
      static_ip_select.trigger('change')
    }
  })

  $(document).on('change', '.o-js-static_ip select', function()
  {
    if ($(this).val() !== '')
    {
      $(this).parent().parent().prev('.o-js-dhcp_id').find('select').val('')

      if ($(this).parent().parent().prev('.o-js-dhcp_id').find('.select2-selection__rendered').length) {
        $(this).parent().parent().prev('.o-js-dhcp_id').find('.select2-selection__rendered').attr('title', '').empty().append('<span class="select2-selection__placeholder"></span>')
      }
    }
  })

  if ($('.o-js-network_resources_cocoon_wrapper').length) {
    $('.o-js-network_resources_cocoon_wrapper').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      if ($(document).find('.computer_network_cards_qty').length) {
        if ($(document).find('.computer_network_cards_qty input').val() == $(document).find('.o-js-network_resources_cocoon_wrapper .o-nested-fields').length) {
          $(document).find('.o-js-network_resources_cocoon_wrapper .add_fields').hide()
        }
      }
    })

    $('.o-js-network_resources_cocoon_wrapper').on('cocoon:after-remove', function(e, insertedItem, originalEvent) {
      if ($(document).find('.computer_network_cards_qty').length) {
        if ($(document).find('.computer_network_cards_qty input').val() > $(document).find('.o-js-network_resources_cocoon_wrapper .o-nested-fields').length) {
          $(document).find('.o-js-network_resources_cocoon_wrapper .add_fields').show()
        }
      }
    })
  }
})
