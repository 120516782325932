import Rails from '@rails/ujs'

Cdx.ready(function () {
  $('.o-js-issue-more-infos-btn').on('click', function(e){
    e.preventDefault()
    var moreInfosElement = $('.o-js-issue-more-infos')
    moreInfosElement.toggleClass('d-none')

    if(moreInfosElement.hasClass('d-none')) {
      $(this).html($(this).attr('data-more-text'))
    } else {
      $(this).html($(this).attr('data-less-text'))
    }
  });

  $('.o-js-issue-more-desc-btn').on('click', function(e) {
    e.preventDefault()
    var desc = $('.o-js-description-end')
    desc.toggleClass('d-none')

    if(desc.hasClass('d-none')) {
      $(this).html($(this).attr('data-more-text'))
    } else {
      $(this).html($(this).attr('data-less-text'))
    }
  });

  $('.o-js-issue-company-filter').on('change', function(){
    var company_filter = $(this);

    $('.o-js-issue-new').each(function() {
      var url = $(this).attr('href').indexOf('?') !== -1 ? $(this).attr('href').split('?')[0] : $(this).attr('href'),
          sep = url.indexOf('?') !== -1 ? '&' : '?',
          q = company_filter.val() != '' ? { issue: { company_id: company_filter.val() } } : {};

      $(this).attr('href', url + sep + $.param(q));
    });
  }).trigger('change');
});
