const initTooltip = require('./tooltip').initTooltip;

Cdx.ready(function () {
    var datatable_locales = {
        fr: {
            sProcessing: 'Traitement en cours...',
            sSearch: 'Rechercher&nbsp;:',
            sLengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
            sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: 'Chargement en cours...',
            sZeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
            sEmptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
            oPaginate: {
                sFirst: 'Premier',
                sPrevious: 'Pr&eacute;c&eacute;dent',
                sNext: 'Suivant',
                sLast: 'Dernier'
            },
            oAria: {
                sSortAscending: ': activer pour trier la colonne par ordre croissant',
                sSortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant'
            }
        }
    };

    datatable_locales['fr']['oBarPartialSelectMsg'] = 'Les _MAX_ éléments de cette liste sont sélectionnés. <a href="#">Selectionner les _TOTAL_ éléments</a>';
    datatable_locales['fr']['oBarTotalSelectMsg'] = 'Les _TOTAL_ éléments sont selectionnées. <a href="#" class="all_resources_selected">Annuler la sélection</a>';

    $(document).on('turbolinks:before-cache', function () {
        $('.dataTable, .simpleDataTable').DataTable().destroy();
        
    });

    if ($('.dataTable').length > 0) {
        $('.dataTable').each(function() {
            var $table = $(this);
            var source = $table.data('source');
            var table = $table.DataTable({
                language: datatable_locales['fr'],
                searching: false,
                processing: false,
                serverSide: true,
                ajax: source,
                pagingType: 'full_numbers',
                lengthMenu: [25, 50, 100],
                order: [],
                dom: "<'row'<'col-sm-5'l><'col-sm-7'p>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5 pt-2'l><'col-sm-7'p>>",
                scrollX: true,
                responsive: true,
                columnDefs: [
                    {
                        className: 'text-center o-shrinked-column sticky-cell sticky-cell--left',
                        targets: [0]
                    },
                    {
                        className: 'text-center o-shrinked-column',
                        targets: [-1]
                    }
                ],
                fnDrawCallback: function (oSettings) {
                    $('.dataTable tbody input[type="checkbox"]').trigger('checkbox:init');
                    setTimeout(function() {
                      $('[data-toggle="tooltip"]').tooltip()
                    }, 300)

                    $('.o-js-list-object-count').html('(' + table.page.info().recordsDisplay + ')');
                },
                rowCallback: function (nRow, data, iDisplayIndex, iDisplayIndexFull) {
                    if ($table.hasClass('datatable_with_status_order')) {
                        if (data.status == 'preparation') {
                            $('td', nRow).addClass('preparation-status');
                        } else if (data.status == 'stock') {
                            $('td', nRow).addClass('stock-status');
                        }
                        else if (data.status == 'archived') {
                            $('td', nRow).addClass('archived-status');
                        }
                    }

                    if ($table.hasClass('warranty_expansions_datatable') && data.activation_finished == 'false') {
                        $('td', nRow).addClass('preparation-status');
                    }
                }
            });
            $('.datatable-filter').each(function(i, e){
                $('.datatable-filter').submit(function (e) {
                    e.preventDefault();
                    const params = serialize(e.currentTarget)
                    sessionStorage.setItem(e.currentTarget.id, params)
                    updateDatatableSource(e, table, source);
                    return false;
                });
                sessionData = sessionStorage.getItem(e.id)
                if (sessionData) {
                    sessionData.split('&').forEach(data => {
                    const [key, value] = decodeURIComponent(data).split('=')
                    const elements = e.querySelectorAll(`[name="${key}"]`)
            
                    // Set field value
                    if (elements.length > 0) {
                        // If data name has multiple elements, get the none hidden one
                        const element = elements.length > 1 ? Array.from(elements).find(el => el.type !== 'hidden') : elements[0]
                        if (['select-one', 'select-multiple', 'type'].includes(element.type)) {
                            
                            if (value) {
                                if (element.type == 'select-multiple'){
                                    value_string_array = value.split(',')
                                    $(element).val(value_string_array).change()
                                    $(element).trigger('change')
                                }
                                else {
                                    $(element).val(value).trigger('change')
                                }
                            }
                        } else if (['checkbox', 'radio'].includes(element.type)) {
                            element.checked = true

                        } else if (['text'].includes(element.type)) {
                            if (value) {
                                date_array = value.split("/");
                                
                                var date_object = new Date(+date_array[2], date_array[1] - 1, +date_array[0]); 
                                $(element).val(date_object.toLocaleDateString('fr-FR')).trigger('apply')
                                $(element).closest('form').trigger('submit')
                            }
                            
                        } else {
                            element.value = value.replace('+', ' ')
                        }
                        
                    }
                    })
                    $('.card-header').collapse('show')
                    $(e).trigger('submit');
                    setTimeout(() => {
                        $(e).trigger('submit');
                      }, 250);
                }
                
            })
            

            $('.btn-reset').click(function(e) {
                const select2Elements = $(this).closest('form')[0].querySelectorAll('.select2-simple, .select2-multiple')
                Array.from(select2Elements).forEach(element => $(element).val('').trigger('change'))
                const elements = $(this).closest('form')[0].querySelectorAll('input')
                Array.from(elements).forEach(element => $(element).val('').trigger('change'))
                sessionStorage.removeItem(this.sessionKey)
                updateDatatableSource($(this).closest('form')[0], table, source)
            })

            $('.datatable-filter .export-button').on('click', function (e) {
                e.preventDefault();

                var exportBtn = $(this),
                sep = exportBtn.attr('href').indexOf('?') !== -1 ? '&' : '?',
                newUrl = exportBtn.attr('href') + sep + $('.datatable-filter').serialize();

                exportBtn.attr('href', newUrl);
            });
        });
    }
});

function updateDatatableSource(e, table, source) {
    var values = '?' + $(e.target).serialize();
    table.ajax.url(source.split('?')[0] + values).load();
    return false;
}

function serialize (form, type){
    const data = Array.from(form.elements)
      .filter(element => element.name && !element.disabled && !['button', 'file', 'submit', 'reset'].includes(element.type))
      .reduce((obj, element) => {
        if (element.type === 'select-multiple') {
          // Get all selections of multi select
          obj[element.name] = Array.from(element.options)
            .filter(option => option.selected)
            .map(option => option.value)
        } else if (element.type === 'hidden') {
          // Don't keep value if has other inputs with same name
          if (form.querySelectorAll(`[name="${element.name}"]`).length === 1) {
            obj[element.name] = element.value
          }
        } else if (!['checkbox', 'radio'].includes(element.type) || element.checked) {
          // Keep only checked checkboxes/radios
          obj[element.name] = element.value
        }
  
        return obj
      }, {})
  
    if (type === 'Object') return data
    if (type === 'JSON') return JSON.stringify(data)
    if (type === 'Array') return Object.entries(data)
  
    const params = new URLSearchParams(data)
  
    if (type === 'URLSearchParams') return params
    return params.toString()
  }
