import Rails from '@rails/ujs'

Cdx.ready(function () {
  $('body').on('change', '.o-js-username, .o-js-domain', function() {
    let username = $('.o-js-username').val()
    let domain = $('.o-js-domain option:selected').html()

    if(domain != undefined && username != undefined && $('.o-js-identifier').attr('data-persisted') == 'false') {
      $('.o-js-identifier').val(username + '@' + domain)
    }
  })

  $('body').on('change', '.o-js-console_manager_account', function() {
    let protocol   = $('.o-js-protocol')
    let contact    = $('.o-js-contact')
    let username   = $('.o-js-username')
    let identifier = $('.o-js-identifier')
    let password   = $('.o-js-password')

    if ($(this).val() === '') {
      // reset form
      protocol.find('option').prop('selected', false)
      protocol.trigger('change.select2')

      contact.find('option').prop('selected', false)
      contact.trigger('change.select2')

      username.val('')

      identifier.parent().show().removeClass('d-none')
      identifier.val('')

      password.parent().show().removeClass('d-none')
      password.val('')
    } else {
      // fill form with console manager account datas
      protocol.find('option[value=exchange]').prop('selected', true)
      protocol.trigger('change.select2')

      Rails.ajax({
        url: '/console_managers/' + $(this).find('option:selected').data('console_manager') + '/console_manager_accounts/' + $(this).val(),
        type: 'GET',
        dataType: 'json',
        success: function(data) {
          if (data.contact === null) {
            contact.find('option').prop('selected', false)
          } else {
            contact.find('option[value=' + data.contact.id + ']').prop('selected', true)
          }
          contact.trigger('change.select2')

          username.val(data.login.split('@')[0])

          identifier.parent().hide()
          identifier.val(data.login)

          password.parent().hide()
          password.val(data.password)
        }
      })
    }
  })
})
