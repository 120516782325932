Cdx.ready(function () {
  if ($('.o-js-required').length) {
    $('.o-js-required').each(function() {
      var label = $(this).find('label');
      var title = $(this).data('required');

      label.append(' <abbr title="' + title + '">*</abbr>');
    });
  }
});
