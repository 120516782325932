Cdx.ready(function () {
  initResourceRelation('body')

  $(document).on('select2:select', '.o-js-related-resource-id', function(e) {
    var data = e.params.data;
    var option = $(this).find('option:selected');

    option.val(data.true_id);
    $(this).closest('.o-js-related-resource-inputs').find('.o-js-related-resource-type').attr('value', data.type);
  });
});

export const initResourceRelation = function(parent_selector) {
  $(parent_selector).find('.o-js-related-resource-id option[data-selected="true"]').each(function(){
    $(this).closest('select').find('option').removeAttr('selected')
    $(this).attr('selected', true)
    $(this).closest('select').trigger('change')
  });
}
