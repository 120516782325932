global.Cdx = {
    ready: function (callback) {
        $(document).on('turbolinks:load', function () {
            callback.call(this);
        });
    },

    locale: function () {
        return $('html').attr('lang');
    }
};

const components = require.context('.', true)
components.keys().forEach(components)
