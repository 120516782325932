Cdx.ready(function () {
  let infoBoxes = $('.o-js-info-box')
  let minHeight = 5
  if(infoBoxes.length > 0) {
    infoBoxes.each(function() {
      if ($(this).outerHeight() > minHeight) {
        minHeight = $(this).outerHeight()
      }
    })

    infoBoxes.each(function() {
      $(this).css('min-height', minHeight)
    })
  }
})
