Cdx.ready(function () {
  var boxCheckAll = $('.o-js-checkbox-check-all');

  if(boxCheckAll.length > 0) {
    boxCheckAll.on('change', function() {
      toggleCheckboxes($(this));
    });
  }

  $(document).on('modal:init', function (e, data) {
    setTimeout(function() {
      var boxCheckAll = $('#modal-wrapper').find('.o-js-checkbox-check-all');

      if(boxCheckAll.length > 0) {
        boxCheckAll.on('change', function() {
          toggleCheckboxes($(this));
        });
      }
    }, 50);
  });
});

function toggleCheckboxes(input) {
  var providerCheckboxes = input.parent().find('.o-js-form-input-checkbox-check-all input[type=checkbox]');

  if(input.prop('checked')) {
    providerCheckboxes.prop('checked', true);
  } else {
    providerCheckboxes.prop('checked', false);
  }
}
