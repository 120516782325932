Cdx.ready(function () {
    if ($('.o-js-tags').length)
    {
        // Add tags
        $('.o-js-tags input').on({
            focusout : function() {
                var txt = this.value.replace(/[^a-z0-9\+\-\.\#]/ig,''); // allowed characters
                if (txt) {
                    $('<a class="badge badge-primary o-js-tag" data-tag="'+txt+'" href="#">'+txt+' &nbsp; <i class="fas fa-times-circle"></i></a>').insertBefore(this);
                    updateHiddenTags();
                }
                this.value = '';
            },
            keyup : function(ev) {
                // if: comma (delimit more keyCodes with | pipe)
                if(/(188)/.test(ev.which)) $(this).focusout();
            }
        });

        // Remove tags
        $('.o-js-tags').on('click', 'a', function(e) {
            e.preventDefault();
            if (confirm('Supprimer ' + $(this).text() + ' ?')) {
                $(this).remove();
                updateHiddenTags();
            };
        });

        // Update hidden tags input on add or remve tags
        function updateHiddenTags() {
            var myObject = new Object();
            $(document).find('.o-js-tag').each(function() {
                // var propName = $(this).data('tag').toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'');
                myObject[$(this).data('tag')] = $(this).data('tag');
            });

            var myString = JSON.stringify(myObject);
            $('.o-js-hidden_tags').val(myString);
        }
    }
});
