Cdx.ready(function () {
  if ($('.o-js-toggle_next_select').length) {
    $('.o-js-toggle_next_select').change(function() {
      toggleNextSelect($(this));
    });
  }

  $(document).on('modal:init', function (e, data) {
    setTimeout(function() {
      var input = $('#modal-wrapper').find('.o-js-toggle_next_select');

      if(input.length > 0) {
        input.on('change', function() {
          toggleNextSelect($(this));
        });
      }
    }, 50);
  });
});

Cdx.ready(function () {
  if ($('.o-js-toggle_next_select_all').length) {
    $('.o-js-toggle_next_select_all').change(function() {
      if ($(this).val().length) {
        $('.o-js-toggle_next_select_target_all').show();
      } else {
        $('.o-js-toggle_next_select_target_all').hide();
        $('.o-js-toggle_next_select_target_all select').val('');
        $('.o-js-toggle_next_select_target_all input').val('');
      }
    });
  }
});

function toggleNextSelect(input) {
  var target = input.closest('form').find('.o-js-toggle_next_select_target');
  var show_value = input.data('show-value')

  if ($.type(show_value) == 'array' && $.inArray(input.val() , show_value) >= 0 || $.type(show_value) == 'string' && input.val() == show_value) {
    target.show();
  } else {
    target.hide();

    if (target.find('select').length) {
      target.find('select').val('');
    }

    if (target.find('input').length) {
      target.find('input').val('');
    }

    if (target.find('input[type="hidden"]').length) {
      target.find('input[type="hidden"]').val('');
    }
  }
}
