Cdx.ready(function () {
    initDaterangepicker();
});

export const initDaterangepicker = function () {
    var data_inputs = $(document).find('.input-group.date');

    data_inputs.each(function () {
        var $this = $(this),
            $input = $this.find('.form-control');

        $input.daterangepicker({
            locale: {
                format: 'DD/MM/YYYY',
                cancelLabel: 'Effacer',
                applyLabel: 'Appliquer'
            },
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 1990
        });

        if ($input.attr('value') == '' || $input.attr('value') === undefined) {
            // $input.val($input.attr('placeholder'));
        }

        $input.on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY'));
        });

        $input.on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
        });
    });
}
