Cdx.ready(function () {
    if ($('.o-js-polymorphic-selector').length) {
        initPolymorphicSelector();
    }
});

export const initPolymorphicSelector = function () {
    $('.o-js-polymorphic-selector').each(function() {
        var selector = $(this);

        selector.on('select2:select', function (e) {
            var type = $(this).parent().next().find('.o-js-polymorphic-type');
            if ($(this).children('option:selected').data('type')) {
                type.val($(this).children('option:selected').data('type'));
            }
            else {
                type.val('');
            }
        });

        selector.on('select2:clear', function (e) {
            $(this).parent().next().find('.o-js-polymorphic-type').val('');
        });
    });
}
