import Inputmask from "inputmask";

Cdx.ready(function () {
  if ($('[data-mask]').length) {
    Inputmask().mask($('[data-mask]'));
  }

  $(document).on('modal:init', function (e, data) {
    setTimeout(function() {
      Inputmask().mask($(document).find('[data-mask]'));
    }, 50);
  });

  if ($('.o-js-cocoon_wrapper').length) {
    $('.o-js-cocoon_wrapper').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      let ipInputs = insertedItem.find('[data-mask]')
      if (ipInputs !== undefined) {
        Inputmask().mask(ipInputs);
      }
    })
  }
});
