global.Cdx = {
    ready: function (callback) {
        $(document).on('turbolinks:load', function () {
            callback.call(this);
        });
    },

    locale: function () {
        return $('html').attr('lang');
    }
};

Cdx.ready(function() {
    var hash = window.location.hash;
    if(hash != ''){
       $("a[href='"+hash+"']").click();
    }
});

const controllers = require.context('.', true)
controllers.keys().forEach(controllers)
