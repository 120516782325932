import autoComplete from '@tarekraafat/autocomplete.js'
import '@tarekraafat/autocomplete.js/dist/css/autoComplete'

Cdx.ready(function () {
  initAutocomplete()
})

export const initAutocomplete = function() {
  if ($('.o-js-autocomplete').length) {
    $('.o-js-autocomplete').each((index, el) => {
      let autocompleteInput = el.querySelector('.o-js-autocomplete_input')
      let autocompleteIdField = el.querySelector('.o-js-autocomplete_id')
      let autocompleteTypeField = el.querySelector('.o-js-autocomplete_type')

      setAutocomplete(index, autocompleteInput, autocompleteIdField, autocompleteTypeField)
      listenAutocomplete(autocompleteInput, autocompleteIdField, autocompleteTypeField)
    })
  }

  if ($('.o-js-cocoon_wrapper').length) {
    $('.o-js-cocoon_wrapper').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      let autocompleteInput = insertedItem.find('.o-js-autocomplete_input')[0]
      if (autocompleteInput !== undefined) {
        let autocompleteIdField = insertedItem.find('.o-js-autocomplete_id')[0]
        let autocompleteTypeField = insertedItem.find('.o-js-autocomplete_type')[0]
        let index = document.querySelectorAll('.o-js-autocomplete_input').length + 1

        setAutocomplete(index, autocompleteInput, autocompleteIdField, autocompleteTypeField)
        listenAutocomplete(autocompleteInput, autocompleteIdField, autocompleteTypeField)
      }
    })
  }
}

function setAutocomplete(index, autocompleteInput, autocompleteIdField, autocompleteTypeField) {
  new autoComplete({
    data: {
      src: async () => {
        const query = autocompleteInput.value
        const url = new URL(autocompleteInput.dataset.url, window.location.origin)
        url.searchParams.append('term', query)
        // Fetch External Data Source
        const source = await fetch(url.toString())
        const data = await source.json()
        // Returns Fetched data
        return data
      },
      key: ['label'],
      cache: false
    },
    sort: (a, b) => {
      if (a.match < b.match) return -1;
      if (a.match > b.match) return 1;
      return 0;
    },
    placeHolder: '',
    selector: function() {
      return autocompleteInput
    },
    threshold: 0,
    debounce: 0,
    searchEngine: 'strict',
    highlight: true,
    maxResults: 5,
    resultsList: {
      render: true,
      container: source => {
        source.setAttribute('id', 'autoComplete_list' + index)
        source.setAttribute('class', 'o-autocomplete_list')
      },
      destination: autocompleteInput,
      position: 'afterend',
      element: 'ul'
    },
    resultItem: {
      content: (data, source) => {
        source.innerHTML = data.match
      },
      element: 'li'
    },
    noResults: () => {
      const result = document.createElement('li')
      result.setAttribute('class', 'autoComplete_no_result')
      result.setAttribute('tabindex', '1')
      result.innerHTML = 'No Results'
      document.querySelector('#autoComplete_list' + index).appendChild(result)
    },
    onSelection: feedback => {
      const selection = feedback.selection.value
      // Change placeholder & fields with the selected value
      autocompleteInput.classList.remove('is-invalid')
      autocompleteInput.classList.add('is-valid')
      autocompleteInput.value = selection.label
      autocompleteIdField.value = selection.id
      autocompleteTypeField.value = selection.type
    }
  })
}

function listenAutocomplete(autocompleteInput, autocompleteIdField, autocompleteTypeField) {
  $(autocompleteInput).on('paste keyup', function() {
    autocompleteInput.classList.remove('is-valid')
    autocompleteIdField.value = ''
    autocompleteTypeField.value = ''
  })
}
