import Rails from '@rails/ujs'

Cdx.ready(function ()
{
  $(document).on('click', '.o-js-copy', function(e) {
    e.preventDefault()
    var self = $(this)

    Rails.ajax({
      url: self.attr('href'),
      type: 'GET',
      success: function(data) {
        var input = $('<input>'),
            id = 'copy-input'
        input.attr('value', data.password).attr('id', id)
        self.parent().append(input)

        copyToClipboard(self, id)
      }
    });
  })
})

function copyToClipboard(button, id)
{
  button.tooltip({trigger: 'manual'})

  let data = document.getElementById(id)
  data.select() /* Select the text field */
  data.setSelectionRange(0, 99999) /*For mobile devices*/
  document.execCommand('copy')

  data.remove()

  button.tooltip('show')
  setTimeout(function() {
    button.tooltip('hide')
  }, 500)
}
