import Rails from '@rails/ujs'

Cdx.ready(function () {
  initAttachment('body')
});

export const initAttachment = function(parent_selector) {
  $(parent_selector).find('.o-js-remove-attachment').on('click', function(e) {
    e.preventDefault()
    var self = $(this)

    Rails.ajax({
      url: self.attr('data-source'),
      type: 'DELETE',
      dataType: 'JSON',
      success: function(data) {
        self.parent().remove()
        $('.o-js-attachment-count').html($('.o-js-remove-attachment').length)
      }
    });
  });
}
