Cdx.ready(function () {
    initTimePicker(document);
});

function initTimePicker(elem) {
    var $elem = $(elem);

    $elem.find('.input-group.time').each(function () {
        var $this  = $(this),
            $input = $this.find('.form-control');

        $input.datetimepicker({
            format: 'LT',
            stepping: 5,
            locale: Cdx.locale()
        });

        $this.find('.input-group-append').on('click', function () {
            $input.data('DateTimePicker').show();
        });
    });
}
