Cdx.ready(function () {
  var questions = $('.o-js-toggle_answer_question input[type="checkbox"]');

  if (questions.length > 0) {
    questions.on('change', function() {
      var self = $(this);

      $.ajax({
        data: $.param({ company_question: { answer: self.prop('checked').toString() } }),
        url: $(this).parent().data('path'),
        method: 'PATCH',
        dataType: 'script',
        success: function() {
          if(self.prop('checked') == true) {
            self.parent().find('.o-js-toggle-answer-btn')[0].click();
          }
        }
      });
    });
  }
});
