Cdx.ready(function () {
    initSelect2();

    $(document).on('turbolinks:before-cache', function () {
        if ($('.select2-simple, .select2-multiple').first().data('select2') != undefined) $('.select2-simple, .select2-multiple').select2('destroy');
    });
});

export const initSelect2 = function(){
    setSelect2()
    if ($('.o-js-cocoon_wrapper').length) {
        $('.o-js-cocoon_wrapper').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
            setSelect2()
        })
      }
}

function setSelect2(){
    $('.select2-simple').each(function () {
        var self = $(this),
            source = self.data('source'),
            options = {},
            default_options = {
                allowClear: !self.prop('multiple'),
                disabled: !!self.attr('disabled'),
                language: Cdx.locale(),
                minimumResultsForSearch: 1,
                placeholder: self.find('option[value=""]').text(),
                width: '100%',
                templateResult: function (obj, container) {
                    if (self.prop('multiple')) {
                        var query = self.data('select2').$container.find('.select2-search__field').val();
                    } else {
                        var query = self.data('select2').$dropdown.find('.select2-search__field').val();
                    }

                    var result_label = $(obj.element).data('result-label');
                    if (result_label != undefined) {
                        obj.result_label = result_label;
                    }

                    if (obj.result_label != undefined && obj.result_label != '') {
                        return query ? $(obj.result_label) : $(obj.result_label);
                    }

                    return query ? $('<span></span>').html(obj.text) : obj.text;
                }
            };

        if (self.hasClass('o-js-select_single_value')) {
            default_options.maximumSelectionLength = 1
        }

        if (source != undefined) {
            var minimumInputLength = $('meta[name="current_company_id"]').length > 0 || self.data('no-minimum-input-length') != undefined ? 0 : 2

            options = {
                minimumInputLength: minimumInputLength,
                ajax: {
                    url: source,
                    quietMillis: 250,
                    dataType: 'json',
                    data: function (params) {
                        return {
                            search: params.term,
                        }
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (obj) {
                                obj.text = obj.text || obj.to_label;

                                if (obj.pk) {
                                    obj.true_id = obj.id
                                    obj.id = obj.pk
                                }

                                return obj;
                            })
                        };
                    },
                    templateResult: function (obj, container) {
                        if (self.prop('multiple')) {
                            var query = self.data('select2').$container.find('.select2-search__field').val();
                        } else {
                            var query = self.data('select2').$dropdown.find('.select2-search__field').val();
                        }

                        var result_label = $(obj.element).data('result-label');
                        if (result_label != undefined) {
                            obj.result_label = result_label;
                        }

                        if (obj.result_label != undefined && obj.result_label != '') {
                            return query ? $(obj.result_label) : $(obj.result_label);
                        }

                        return query ? $('<span></span>').html(obj.text) : obj.text;
                    },
                }
            }
        }

        options = $.extend(true, default_options, options);
        self.select2(options);
    });

    $('.select2-multiple').each(function () {
        var self = $(this),
            source = self.data('source'),
            options = {
                multiple: true
            },
            default_options = {
                allowClear: !self.prop('multiple'),
                disabled: !!self.attr('disabled'),
                minimumResultsForSearch: 1,
                language: Cdx.locale(),
                placeholder: self.find('option[value=""]').text(),
                width: '100%',
                templateResult: function (obj, container) {
                    if (self.prop('multiple')) {
                        var query = self.data('select2').$container.find('.select2-search__field').val();
                    } else {
                        var query = self.data('select2').$dropdown.find('.select2-search__field').val();
                    }

                    var result_label = $(obj.element).data('result-label');
                    if (result_label != undefined) {
                        obj.result_label = result_label;
                    }

                    if (obj.result_label != undefined && obj.result_label != '') {
                        return query ? $(obj.result_label) : $(obj.result_label);
                    }

                    return query ? $('<span></span>').html(obj.text) : obj.text;
                }
            };

        if (source != undefined) {
            var minimumInputLength = $('meta[name="current_company_id"]').length > 0 || self.data('no-minimum-input-length') != undefined ? 0 : 2

            options = {
                minimumInputLength: minimumInputLength,
                ajax: {
                    url: source,
                    quietMillis: 250,
                    dataType: 'json',
                    data: function (params) {
                        return {
                            search: params.term,
                        }
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (obj) {
                                obj.text = obj.text || obj.to_label;

                                if (obj.pk) {
                                    obj.true_id = obj.id
                                    obj.id = obj.pk
                                }

                                return obj;
                            })
                        };
                    },
                    templateResult: function (obj, container) {
                        if (self.prop('multiple')) {
                            var query = self.data('select2').$container.find('.select2-search__field').val();
                        } else {
                            var query = self.data('select2').$dropdown.find('.select2-search__field').val();
                        }

                        var result_label = $(obj.element).data('result-label');
                        if (result_label != undefined) {
                            obj.result_label = result_label;
                        }

                        if (obj.result_label != undefined && obj.result_label != '') {
                            return query ? $(obj.result_label) : $(obj.result_label);
                        }

                        return query ? $('<span></span>').html(obj.text) : obj.text;
                    },
                }
            }
        }

        options = $.extend(true, default_options, options);
        self.select2(options);
    });

    $(document).on('focus', '.select2.select2-container', function (e) {
        var isOriginalEvent = e.originalEvent
        var isSingleSelect = $(this).find(".select2-selection--single").length > 0

        if (isOriginalEvent && isSingleSelect) {
            $(this).siblings('select:enabled').select2('open');
        }
    });
}
