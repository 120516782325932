const initCompanySelector = require('./company_selector').initCompanySelector;
const initSelect2 = require('./select2').initSelect2;
const initDaterangepicker = require('./daterangepicker').initDaterangepicker;
const initAutocomplete = require('./autocomplete').initAutocomplete;
const initPolymorphicSelector = require('./polymorphic_selector').initPolymorphicSelector;
const initResourceRelation = require('../controllers/resource_relation').initResourceRelation;
const initProvision = require('../controllers/provision').initProvision;
const initAttachment = require('../controllers/attachment').initAttachment;

Cdx.ready(function () {
  $(document).on('modal:init', function (e, data) {
    $receiver = $('#modal-wrapper');

    $receiver.find('.modal-content').html(data);

    // INIT FORM INPUTS
    // $('.file-container').trigger('form-file-container:init');

    $receiver.modal('show');
    // initDatePicker(document);
    initCompanySelector();
    initPolymorphicSelector();
    initSelect2();
    initDaterangepicker();
    initAutocomplete();
    initResourceRelation($receiver);
    initProvision($receiver);
    initAttachment($receiver);

    var computer_type_select = $(document).find('.o-js-computer_type_select select');

    if (computer_type_select.length) {
      var computer_type_virtual_enable = $(document).find('.o-js-computer_type_virtual_enable');
      var computer_type_virtual_disable = $(document).find('.o-js-computer_type_virtual_disable');

      computer_type_select.on('change', function() {
        if ($(this).val() == 'virtual') {
          computer_type_virtual_enable.show();
          computer_type_virtual_disable.each(function() {
            $(this).hide();
          });
        } else {
          computer_type_virtual_enable.hide();
          computer_type_virtual_enable.find('select').val('');
          computer_type_virtual_disable.each(function() {
            $(this).show();
          });
        }
      }).trigger('change');
    }
  });
});
