Cdx.ready(function () {
  $('body').on('submit', '.o-js-disk-modal-form', function() {
    var inputs = $(".o-js-computer-form :input[value!=''][name^='computer']"),
        source = $(this).attr('data-source'),
        sep = source.indexOf('?') !== -1 ? '&' : '?'

        // Some replace to prevent too long url for GET request
        serialized = inputs.serialize()
                           .replaceAll('network_resources_attributes', 'nsa')
                           .replaceAll('warranties_attributes', 'wa');

    $(this).attr('action', source + sep + serialized);
  });
});
