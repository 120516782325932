Cdx.ready(function () {
  initProvision('body')
});

export const initProvision = function(parent_selector) {
  $(parent_selector).find('.o-js-provision-already-installed').on('change', function() {
    if($(this).prop('checked')) {
      $(this).closest('form').find('.o-js-provision-not-already-installed').hide();
    } else {
      $(this).closest('form').find('.o-js-provision-not-already-installed').show();
    }
  }).trigger('change');
}
