Cdx.ready(function () {
  $(document).on('change', '.custom-file-input', function() {
    var parent = $(this).closest('.custom-file');

    if(this.files.length == 1) {
      parent.find('.custom-file-label').html(this.files[0].name);
    } else {
      parent.find('.custom-file-label').html(this.files.length + ' fichier(s)');
    }
  });
});
