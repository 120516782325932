var placeSelectorOptions = [];

const initSelect2 = require('./select2').initSelect2;

Cdx.ready(function () {
    initSelect2();

    $(document).on('select2:select select2:clear', '.o-js-company-selector', function (e) {
        updateSelectorDependOnCompanyPlaces(e.params.data.place_ids);
    });

    if ($('.o-js-context-company-selector').length > 0) {
        $(document).on('select2:select select2:clear', '.company-context-sidebar .o-js-context-company-selector', function (e) {
            $(this).closest('form').submit();
        });
    }

    if ($('.o-js-place-selector').length > 0) {
        initCompanySelector();

        $('.company-context-sidebar .o-js-context-company-selector').on('change', function() {
            $('.company-context-sidebar .o-js-place-selector').val('').trigger('change');
        });

        $(document).on('select2:select select2:clear', '.company-context-sidebar .o-js-place-selector', function (e) {
            $(this).closest('form').submit();
        });

        $(document).on('select2:select', '.o-js-company-selector', function (e) {
            updatePlaceOptions($(this), '', placeSelectorOptions);

            var networkSelect = $(this).closest('form').find('.o-js-network_select');
            var domainSelector = $(this).closest('form').find('.o-js-ad-domain-selector');

            if (networkSelect.length > 0) {
                networkSelect.val('').trigger('change');
            }

            if (domainSelector.length > 0) {
                domainSelector.val('').trigger('change');
            }
        });

        $(document).on('select2:clear', '.o-js-company-selector', function (e) {
            $(this).closest('form').find('.o-js-place-selector').closest('.form-group').hide();
            $(this).closest('form').find('.o-js-place-selector').val('').trigger('change');
        });
    }

    $(document).on('select2:select', '.o-js-company-selector', function (e) {
        updateSelectorDependOnCompany($(this), true);

        if ($(this).closest('.company-context-sidebar').length == 0) {
            var domainSelector = $(this).closest('form').find('.o-js-ad-domain-selector');

            if (domainSelector.length > 0) {
                domainSelector.val('').trigger('change');
            }
        }
    });

    if ($('.o-js-company-selector').length > 0 && $('.o-js-company-selector').val() != '') {
        updateSelectorDependOnCompany($('.o-js-company-selector'), false);
    }

    $(document).on('select2:select', '.o-js-place-selector', function (e) {
        updateSelectorDependOnPlace($(this), true);

        if ($(this).closest('.company-context-sidebar').length == 0) {
          var networkSelect = $(this).closest('form').find('.o-js-network_select');

            if (networkSelect.length > 0) {
                networkSelect.val('').trigger('change');
            }
        }
    });

    if ($('.o-js-place-selector').length > 0) {
        $('.o-js-place-selector').each(function() {
            if ($(this).closest('.company-context-sidebar').length == 0 && $(this).val() != '') {
                updateSelectorDependOnPlace($(this), false);
            }
        });
    }
});

// Update network or ad-domain field options
function updatePlaceOptions(el, val, placeSelectorOptions) {
    var form = $(el).closest('form'),
        select = form.find('.o-js-place-selector');

    if (select.length) {
        placeSelectorOptions[select.attr('id')].detach();

        placeSelectorOptions[select.attr('id')].filter(function () {
            return $(this).data('company-ids').includes(parseInt(select.closest('form').find('.o-js-company-selector').val())) || $(this).val() == '';
        }).appendTo(select);

        select.val(val).trigger('change');
        select.closest('.form-group').show();
    }
}

function updateSelectorDependOnCompany(company_element, force_clear) {
    $('.o-js-depend-on-company').each(function(){
        var element = $(this);

        // Select2 selector
        if(element.hasClass('select2-simple') || element.hasClass('select2-multiple')) {
            // Autocompleter case (change source ajax url)
            if(element.attr('data-source') != undefined) {
                var url = element.attr('data-source'),
                select2_options = element.data('select2').options.options,
                new_url = false;

                if (url.includes('autocomplete')) {
                    var sep = url.indexOf('?') !== -1 ? '&' : '?'

                    if (element.hasClass('o-js-depend-on-company-group')) {
                        var q = { q: { company_siblings_inc: company_element.val() } }
                    } else {
                        var q = { q: { company_id_eq: company_element.val() } }
                    }

                    var new_url = url + sep + $.param(q);
                } else if (url.includes('company_id=')) {
                    var url_exp = url.split('company_id='),
                    new_url = url_exp[0] + 'company_id=' + company_element.val();
                }

                if (new_url) {
                    var select2_new_options = { ajax: { url: new_url } },
                    options = $.extend(true, select2_options, select2_new_options);

                    element.select2(options);
                }
            }

            // Other case (use data-attribute to hide/show the good options)
            else {
                element.find('option').prop('disabled', true);
                element.find('option[data-company-id=' + company_element.val() + ']').prop('disabled', false);
            }

            if(force_clear) element.val('');
            element.trigger('change');
        }
    });
}

// Update network field options
function updateSelectorDependOnPlace(place_element, force_clear) {
    $('.o-js-depend-on-place').each(function(){
        var element = $(this);

        // Select2 selector
        if(element.hasClass('select2-simple')) {
          // Autocompleter case (change source ajax url)
          if(element.attr('data-source') != undefined) {
            var url = element.attr('data-source'),
            select2_options = element.data('select2').options.options,
            new_url = false;

            if (url.includes('autocomplete')) {
              var sep = url.indexOf('?') !== -1 ? '&' : '?'

              if (element.hasClass('o-js-depend-on-place')) {
                  var q = { q: { places_id_in: place_element.val() } }
              }

              var new_url = url + sep + $.param(q);
            }

            if (new_url) {
              var select2_new_options = { ajax: { url: new_url } },
              options = $.extend(true, select2_options, select2_new_options);

              element.select2(options);
            }
          }
        }
    });
}

// Update ad domains field options
function updateSelectorDependOnCompanyPlaces(places) {
    $('.o-js-depend-on-company-places').each(function(){
        var element = $(this);

        // Select2 selector
        if(element.hasClass('select2-simple')) {
            // Autocompleter case (change source ajax url)
            if(element.attr('data-source') != undefined) {
                var url = element.attr('data-source'),
                select2_options = element.data('select2').options.options,
                new_url = false;

                if (url.includes('autocomplete')) {
                    if (element.hasClass('o-js-depend-on-company-places')) {
                        var q = { q: { places_id_in: places } }
                    }

                    url = url.split('?')[0]; // Remove the old q parameters
                    var new_url = url + '?&' + $.param(q);
                }

                if (new_url) {
                    var select2_new_options = { ajax: { url: new_url } },
                    options = $.extend(true, select2_options, select2_new_options);

                    element.select2(options);
                }
            }
        }
    });
}

export const initCompanySelector = function () {
    $('.o-js-place-selector').each(function (e) {
        placeSelectorOptions[$(this).attr('id')] = $(this).children();

        if ($(this).closest('form').find('.o-js-company-selector').val()) {
            updatePlaceOptions($(this).closest('form').find('.o-js-company-selector'), $(this).val(), placeSelectorOptions);
        }
    });
}
