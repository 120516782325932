Cdx.ready(function ()
{
    const activeTab = localStorage.getItem('activeTab');

    if (activeTab) {
        $('.nav-tabs .nav-link[href="' + activeTab + '"]').tab('show');
        localStorage.removeItem('activeTab');
    }

    $('form').submit(function() {
        if ($('.nav-tabs .nav-link.active').length) {
            localStorage.setItem('activeTab', $('.nav-tabs .nav-link.active').attr('href'));
        }
    })
})
